import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon src={icon} alt={label} width="32" height="32" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 16.666%;
  margin-top: 4.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    width: 50%;
    margin-top: 3.125rem;
  }
`

const Icon = styled.img`
  width: auto;
  max-width: 3.125rem;
  height: 2rem;
  margin-bottom: 1.375rem;

  @media (max-width: 1199px) {
    height: 1.875rem;
    margin-bottom: 1.25rem;
  }
`

const Label = styled.div`
  max-width: 11.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.125rem;
  margin: auto;
  text-transform: uppercase;
`
