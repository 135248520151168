import styled from '@emotion/styled'
import { Dimension, Highlight, Pax } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  highlight?: string
  pax?: string
  size?: string
}

export const Info = memo(function Info({ highlight, pax, size }: Props) {
  if (!pax || !size) {
    return null
  }

  return (
    <Container dial={4} row wrap>
      {size ? (
        <Item dial={4} row>
          <Dimension />
          {size}
        </Item>
      ) : null}

      {pax ? (
        <Item dial={4} row>
          <Pax />
          {pax}
        </Item>
      ) : null}

      {highlight ? (
        <Item dial={4} row>
          <Highlight />
          {highlight}
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.5rem;

  @media (max-width: 1199px) {
    display: block;
  }
`

const Item = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
  &:last-of-type {
    &::after {
      display: none;
    }
  }
  &::after {
    content: '';
    width: 0.125rem;
    height: 2.25rem;
    background: ${rgba(theme.colors.variants.primaryDark3, 0.1)};
    margin: 0 2.5rem;
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark3};
    margin-right: 0.75rem;
  }

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
    &::after {
      display: none;
    }
  }
`
