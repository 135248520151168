import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Gallery } from './Gallery'
import { Info } from './Info'

export interface Props {
  bookingButtonURL?: string
  description?: string
  images?: ImageProps[]
  highlight?: string
  languageCode: string
  pax?: string
  size?: string
  thumbnails?: ImageProps[]
  title?: string
}

export const Room = memo(function Room({
  bookingButtonURL,
  description,
  images,
  highlight,
  languageCode,
  pax,
  size,
  thumbnails,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      {images && images.length > 0 ? (
        <Wrapper row stretch wrap>
          <LeftSide>
            <Gallery images={images} />
          </LeftSide>

          <RightSide>
            {thumbnails
              ? thumbnails.map((item, index) => (
                  <Thumb key={index}>
                    <LazyLoadComponent>
                      <Image {...item} />
                    </LazyLoadComponent>
                  </Thumb>
                ))
              : null}
          </RightSide>
        </Wrapper>
      ) : null}

      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <Info highlight={highlight} pax={pax} size={size} />
      </FadeInUp>

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {bookingButtonURL ? (
        <FadeInUp>
          <CTA
            label={useVocabularyData('book-now', languageCode)}
            URL={bookingButtonURL}
            rel="noopener"
            target="_blank"
          />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  padding: 6rem 12.639vw;
  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-bottom: 3rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.5rem;
  }
`

const LeftSide = styled.div`
  width: calc(82% - 1.5rem);
  margin-right: 1.5rem;

  @media (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
  }
`

const RightSide = styled.div`
  width: 18%;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Thumb = styled.div`
  height: calc(50% - 0.75rem);
  position: relative;
  &:nth-of-type(2) {
    margin-top: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
`

const Description = styled.div`
  max-width: 59.444vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 3rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3rem;
`
